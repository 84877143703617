import React from "react";
import { Suspense } from "react";

import { Link } from "react-alice-carousel";

import SocialMediaFloat from "./SocialMediaFloat";

import Header from "./Header";
import SectionFirst from "./SectionFirst";
import SectionSecond from "./SectionSecond";
import SectionThird from "./SectionThird";
import SectionFourth from "./SectionFourth";
import Banner from "./banner";
import SectionFifth from "./SectionFifth";
import GalleryTitle from "./GalleryTitle";
import Fixture from "./Fixture";

import FixturesTitle from "./FixturesTitle";



import Footer from "./Footer";

export default function Layout (){
    return(
        <>
        
        <Header/>
        
        <SocialMediaFloat/>
       
       
        <SectionFirst/>


        <Banner/> 


        <SectionThird/>

        <SectionFourth/>
        
        <FixturesTitle/>  
        
        <Fixture/>
        
        {/* <SectionSecond/> */}
              
        <GalleryTitle/>

        <SectionFifth/>

        <Footer/>

        </>
    )
}

