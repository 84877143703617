import React from "react";
import './banner.css';  // Import the CSS file

const Banner = () => {
  return (
    <div className="banner-container">
      {/* Desktop Image Container */}
      <div className="desktop-banner">
        <img
          src="https://cdn.shopify.com/s/files/1/0653/9830/9053/files/front_banner-WHITE.jpg?v=1733832452"
          alt="Banner"
          className="banner-image"
        />
      </div>

      {/* Mobile Image Container */}
      <div className="mobile-banner">
        <img
          src="https://cdn.shopify.com/s/files/1/0653/9830/9053/files/FRONT_banner_-mobile_size-WHITE.jpg?v=1733832451"
          alt="Banner"
          className="banner-image"
        />
      </div>
    </div>
  );
};

export default Banner;
