import React, { useState, useEffect, useRef } from 'react';
import './FloatMedia.css'; // Import the CSS file

const SocialMediaFloat = () => {
  // State to track whether icons are shown or not for mobile view
  const [showIcons, setShowIcons] = useState(false);

  // Ref for floating icons container
  const iconsRef = useRef(null);

  // Determine whether the window width is mobile or desktop
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // Close icons if clicked outside the icons container
    const handleClickOutside = (event) => {
      if (iconsRef.current && !iconsRef.current.contains(event.target)) {
        setShowIcons(false);
      }
    };

    // Close icons if user scrolls (only for mobile view)
    const handleScroll = () => {
      if (isMobile) {
        setShowIcons(false); // Hide the icons when scrolling
      }
    };

    // Add event listeners
    if (isMobile) {
      document.addEventListener('click', handleClickOutside);
      window.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listeners on unmount or resizing
    return () => {
      if (isMobile) {
        document.removeEventListener('click', handleClickOutside);
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);

  return (
    <div
      ref={iconsRef}
      className={`floating-icons ${isMobile ? 'floating-icons-mobile' : 'floating-icons-desktop'}`}
    >
      {/* Toggle Button (only for mobile view) */}
      {isMobile && (
        <div
          className="toggle-button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from propagating to the document
            setShowIcons(!showIcons); // Toggle the visibility of icons for mobile
          }}
        >
          <img
            src="https://github.com/aayushwellness/RCAssets/blob/main/social-media.png?raw=true"
            alt="Menu"
          />
        </div>
      )}

      {/* List of icons */}
      <ul className={`icon-list ${isMobile && showIcons ? 'show' : ''}`}>

      
      <li className="icon-item">
          <a
            href="https://x.com/NorthernChall?s=09"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/twitter.png?raw=true"
              alt="Twitter"
            />
          </a>
        </li>
      
      
      
      
      <li className="icon-item">
          <a
            href="https://www.instagram.com/northernchallengers_?igsh=MWsydnR6bDN5YWptOA=="
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/instagram.png?raw=true"
              alt="Instagram"
            />
          </a>
        </li>




        
        
        

        <li className="icon-item">
          <a
            href="https://www.facebook.com/profile.php?id=61570161389122"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/facebook.png?raw=true"
              alt="Facebook"
            />
          </a>
        </li>








        <li className="icon-item">
          <a
            href="https://www.linkedin.com/company/colabcloudplatformslimited/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/youtube.png?raw=true"
              alt="YouTube"
            />
          </a>
        </li>
        <li className="icon-item">
          <a
            href="https://www.linkedin.com/company/colabcloudplatformslimited/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/linkedin.png?raw=true"
              alt="LinkedIn"
            />
          </a>
        </li>
           

        <li className="icon-item">
          <a
            href="https://wa.me/+918976968900"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/whatsapp.png?raw=true"
              alt="WhatsApp"
            />
          </a>
        </li> 


      </ul>
    </div>
  );
};

export default SocialMediaFloat;
