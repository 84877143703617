import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        {/* Row for different footer sections */}
        <div className="row">
          {/* Column 1: Logo (col-3) */}
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="footer-logo">
              <img
                src="https://github.com/aayushwellness/RCAssets/blob/main/Logo.png?raw=true"
                alt="Cricket Logo"
                width="150"
                height="150"
              />
            </div>
          </div>

          {/* Column 2: Quick Links and Text (col-6) */}
          <div className="col-lg-6 col-md-4 col-sm-12">
            {/* <h5 className="footer-title">Quick Links</h5> */}
            <ul className="list-unstyled">
              
              {/* <li><a href="/">Schedule</a></li> */}
              {/* <li><a href="/">Squad details</a></li> */}
              <li><a href="/">Join Our Team</a></li>
              <li><a href="mailto:challengersnorthern@gmail.com">Contact Us</a></li>
              <li><a href="/">Our Policy</a></li>
              <li><a href="/">About Us</a></li>
              
            </ul>
            
            <div className="social-media">
              <a href="#!" className="text-white">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a href="#!" className="text-white">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#!" className="text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Column 3: Powered By Text and Banner Image (col-3) */}
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="powered-by">
              <p className="powered-text">Powered By</p>
              <div className="banner-image2">
                <img
                  src="https://github.com/aayushwellness/RCAssets/blob/main/Logo/Colab%20New%20Logo%20updated%20-%2004-12-2024%20-%20white-01.png?raw=true"  // Replace with actual banner image URL
                  alt="Colab Cloud Platforms"
                  className="banner-img2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        © 2024 Northern Challengers. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
