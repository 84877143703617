import React, { useState, useEffect } from "react";
import "./Header.css"; // Import the CSS file
import { Link } from 'react-router-dom';
import About from "./About";



export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Handle scroll to close the menu
  useEffect(() => {
    const handleScroll = () => {
      setIsMenuOpen(false);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  
  const handleSearch = () => {
    setIsPopupVisible(true);
    setTimeout(() => {
      setIsPopupVisible(false);
    }, 2000); // Popup will disappear after 2 seconds
  };

  return (
    <>
      {/* Marquee Text */}
      <div className="marquee-container">
        <div className="marquee-text">
          <span className="marquee-text-content">
            Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 | Welcome to Northern Challengers! Get ready for action on 12th December 2024 |
          </span>
        </div>
      </div>

      {/* Main Header */}
      <header className="header">
        {/* Top Section */}
        <div className="top-bar">
          {/* Logo Section */}
          <Link to='/'>
          <div className="logo-section">
            <img
              src="https://github.com/aayushwellness/RCAssets/blob/main/Logo.png?raw=true"
              alt="Northern Challengers"
              className="logo-img"
            />
          </div>
          </Link>

          {/* Hamburger Menu for Mobile */}
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
            <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
            <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
          </div>

          {/* Everything except the hamburger icon */}
          <div className={`header-content ${isMenuOpen ? "open" : ""}`}>
            <div className="nav-links">
              
             
            </div>

            {/* Search & Language Options */}
            <div className="actions">

         {/*     <a href="/about" className="shop-link">About Us</a> */}
              
              <a href="/allfixtures" className="sign-in-link">Fixtures</a>
              <div className="search-container">
                <input type="text" placeholder="Search" className="search-input" />
                <button className="search-button" onClick={handleSearch}>🔍</button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Popup Message */}
      {isPopupVisible && (
        <div className="popup">
          <p>No Result Found</p>
        </div>
      )}
    </>
  );
}
