import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import About from './About';
import Fixture from './Fixture';
import AllFixtures from './AllFixtures';
import Layout from './Layout';



function App() {
  return (
    <>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}/>
        <Route path='about' element={<About/>}/>
        <Route path='allFixtures' element={<AllFixtures/>}/>
      
      </Routes>
    </BrowserRouter>
    </>
  )
  ;
}

export default App;
