import React, {useEffect, useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import AboutContent from "./AboutContent";


const About = () =>{
    return(
        <>
        <Header/>

        <AboutContent/>

        <Footer/>       
        </>
    )
}

export default About;