import React, { useEffect } from "react";
import './AllFixtures.css';
import Header from "./Header";
import Footer from "./Footer";
import Fixture from "./Fixture";
import { Link } from 'react-router-dom';

export default function AllFixtures() {
      const isMobile = window.innerWidth < 768;

  const mobileImages = [
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/inside_FIXTURES_BANNER.jpg?v=1733837876",
  ];

  const desktopImages = [
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/inside_FIXTURES_BANNER_-_landscape.jpg?v=1733837874",
  ];

  const images = isMobile ? mobileImages : desktopImages;
  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []); // Empty dependency array ensures this runs only once, when the component mounts

  return (
    <>
      <Header />

    <div className="slider-container">
      <img
        loading="lazy"
        src={images[0]} // Show only the first image
        alt="Static slide"
        className="slider-image"
      />
    </div>
  
      <Footer />
    </>
  );
}
