import React from 'react';
import './AboutContent.css'; // CSS for styling

const AboutContent = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>About Cricket</h1>
      </header>
      
      <section className="about-section">
        <h2>History of Cricket</h2>
        <p>
          Cricket is a bat-and-ball game that originated in England during the 16th century.
          It evolved into the international sport we know today in the 18th century. The first
          Test match was played in 1877 between England and Australia, establishing cricket as
          a global sport.
        </p>
      </section>

      <section className="about-section">
        <h2>Why Cricket Matters</h2>
        <p>
          Cricket unites fans across the globe, transcending cultures and boundaries. It fosters
          teamwork, discipline, and sportsmanship. With its rich traditions and thrilling moments,
          cricket inspires millions and contributes significantly to the world of sports.
        </p>
      </section>

      <section className="about-section">
        <h2>Formats of the Game</h2>
        <ul>
          <li><strong>Test Cricket:</strong> The longest format, played over five days.</li>
          <li><strong>One-Day Internationals (ODIs):</strong> Limited to 50 overs per side.</li>
          <li><strong>T20 Cricket:</strong> A shorter, more explosive format with 20 overs per side.</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>Notable Tournaments</h2>
        <p>
          Major cricket tournaments include the ICC Cricket World Cup, ICC T20 World Cup, The Ashes,
          Indian Premier League (IPL), and more. These events showcase top talent and captivate
          audiences worldwide.
        </p>
      </section>

      
    </div>
  );
};

export default AboutContent;
