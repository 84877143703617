import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './SectionSecond.css';
import './global.css';

// Match data
const SectionSecond = () => {
  const matches = [
    {
      match: "1",
      date: "12 Dec, 2024",
      team1: "Northern Challengers",
      team2: "Southern Spartans",
      logo1: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/NorthernChallengersLog.jpeg?raw=true", // Team 1 logo link
      logo2: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/SouthernSpartansLog.jpeg?raw=true",  // Team 2 logo link
      score1: "",
      score2: "",
      result: " ",
    },
    {
      match: "2",
      date: "14 Dec, 2024",
      team1: "Northern Challengers",
      team2: "Mumbai Marines",
      logo1: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/NorthernChallengersLog.jpeg?raw=true", // Team 1 logo link
      logo2: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/MumbaiMarinesLog.jpeg?raw=true", // Team 2 logo link
      score1: "",
      score2: "",
      result: "",
    },

    {
      match: "3",
      date: "16 Dec, 2024",
      team1: "Northern Challengers",
      team2: "MP Tigers",
      logo1: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/NorthernChallengersLog.jpeg?raw=true", // Team 1 logo link
      logo2: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/MP-TIGER.jpeg?raw=true", // Team 2 logo link
      score1: "",
      score2: "",
      result: "",
    },
    {
      match: "4",
      date: "17 Dec, 2024",
      team1: "Northern Challengers",
      team2: "UP Brij Stars",
      logo1: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/NorthernChallengersLog.jpeg?raw=true", // Team 1 logo link
      logo2: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/Up-Brij-Stars-Final-logo-0.jpeg?raw=true", // Team 2 logo link
      score1: "",
      score2: "",
      result: "",
    },

    {
      match: "5",
      date: "18 Dec, 2024",
      team1: "Northern Challengers",
      team2: "Rajasthan Regals",
      logo1: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/NorthernChallengersLog.jpeg?raw=true", // Team 1 logo link
      logo2: "https://github.com/aayushwellness/RCAssets/blob/main/Logo/Rajasthan-Regals-new-white-tex.jpeg?raw=true", // Team 2 logo link
      score1:"",
      score2:"",
      result:"",
    },


  ];

  // Create an item for each match
  const matchItems = matches.map((match, index) => (
    <div className="match-card" key={index}>
      <div className="match-header">
        <span className="match-date">Match {match.match}, {match.date}</span>
        <span className="match-status">Upcoming Match</span>
      </div>
      <div className="match-details">
        <div className="team">
          <img src={match.logo1} alt={match.team1} className="team-logo" /> {/* Display logo for team 1 */}
          <p>{match.score1}</p>
        </div>
        <div className="team">
          <img src={match.logo2} alt={match.team2} className="team-logo" /> {/* Display logo for team 2 */}
          <p>{match.score2}</p>
        </div>
      </div>

      


      <p className="result">{match.result}</p>
    </div>
  ));

  return (
    <div className="fixtures-container">
      <div className="match-cards-row">
        <AliceCarousel
          mouseTracking
          items={matchItems}
          autoPlay
          infinite
          autoPlayInterval={5000}
          animationDuration={2000}
          disableDotsControls
          disableButtonsControls
          responsive={{
            0: {
              items: 1, // Show 1 card at a time on mobile
            },
            200: {
              items: 1, // Show 1 card at a time on small screens
            },
            600: {
              items: 2, // Show 2 cards at a time on medium screens
            },
            1024: {
              items: 3, // Show 3 cards at a time on larger screens
            },
          }}
        />
      </div>
    </div>
  );
};

export default SectionSecond;
