import React from 'react';
import { Link } from 'react-router-dom'; // Importing Link from react-router-dom
import './Fixture.css'; // Import the CSS file

export default function Fixture  () {

    const isMobile = window.innerWidth < 768;

  const mobileImages = [
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/FIXTURES_BANNER.jpg?v=1733834500",
  ];

  const desktopImages = [
    "https://cdn.shopify.com/s/files/1/0653/9830/9053/files/FIXTURES_BANNER_-_landscape.jpg?v=1733834500",
  ];

  const images = isMobile ? mobileImages : desktopImages;

  return (<>
    <div className="slider-container">
      <img
        loading="lazy"
        src={images[0]} // Show only the first image
        alt="Static slide"
        className="slider-image"
      />
    </div>
    
    {/* Link to navigate to another page */}
    <div style={{ marginTop: '10px' }}>
      <Link to="/allfixtures" className="fixture-link">
        See all the Fixtures
      </Link>
    </div>
  </>
  );





}



































