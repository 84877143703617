import React from "react";
import "./SectionThird.css"; // Import the CSS file

const SectionThird = () => {
  return (
    <div className="squad-section">
      {/* Black line above the text section */}
      {/* <div className="black-line"></div> */}
      
      {/* <h1 className="squad-text">SQUAD</h1> */}
    </div>
  );
};

export default SectionThird;
