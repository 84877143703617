import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './SectionFourth.css';  // Import the CSS file

const handleDragStart = (e) => e.preventDefault();

const items = [
  <div className="card">
    <img
      src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p1%20(1).jpg?raw=true"
      alt="Slide 1"
      onDragStart={handleDragStart}
      role="presentation"
    />
    
  </div>,

         <div className="card">
    <img
      src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p3%20(1).jpg?raw=true"
      alt="Slide 3"
      onDragStart={handleDragStart}
      role="presentation"
    />
  
  </div>,

          <div className="card">
    <img
      src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p5%20(1).jpg?raw=true"
      alt="Slide 5"
      onDragStart={handleDragStart}
      role="presentation"
    />
   
   
  </div>,
  
  <div className="card">
    <img
      src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p2%20(1).jpg?raw=true"
      alt="Slide 2"
      onDragStart={handleDragStart}
      role="presentation"
    />
    
  </div>,

 

<div className="card">
<img
  src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p4%20(1).jpg?raw=true"
  alt="Slide 4"
  onDragStart={handleDragStart}
  role="presentation"
/>

</div>,
  


  <div className="card">
    <img
      src="https://github.com/aayushwellness/RCAssets/blob/main/Players/p6%20(1).jpg?raw=true"
      alt="Slide 6"
      onDragStart={handleDragStart}
      role="presentation"
    />
    
  </div>,

 

  
];

const SectionFourth = () => (
  <div style={{ width: '100%', margin: '0 auto' }}>
    <AliceCarousel
      mouseTracking
      items={items}
      autoPlay
      infinite
      autoPlayInterval={2000}
      animationDuration={800}
      disableDotsControls
      disableButtonsControls
      responsive={{
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1024: {
          items: 3,
        },
        1280: {
          items: 4,
        },
      }}
    />
  </div>
);

export default SectionFourth;
