import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './SectionFifth.css'; // Import the CSS file

// Prevent drag start (no visual drag behavior for images)
const handleDragStart = (e) => e.preventDefault();

// Custom onMouseDown handler to prevent card from triggering a link click on drag
const handleMouseDown = (e) => {
  // Set a flag to track if it's a drag or a click
  e.preventDefault();
};

const items = [


  <div className="card1" onMouseDown={handleMouseDown}>
    <a 
      href="https://www.ottplay.com/sports/news/big-cricket-league-when-and-where-to-watch-inaugural-edition-of-tournament-featuring-amateur-and-professional-cricketers/152c5f9261584" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <img
        src="https://github.com/aayushwellness/RCAssets/blob/main/LatestUpdate/g3.jpg?raw=true"
        alt="Slide 3"
        onDragStart={handleDragStart}
        role="presentation"
      />
      <div className="date">11 DEC, 2024</div>
      <div className="title">Big Cricket League: Team composition and schedule of Big Cricket League!</div>
      <div className="brief">The Big Cricket League, a new T20 tournament, pairs amateur cricket enthusiasts with former international players from India and abroad, promising exciting matches in its debut edition.</div>
    </a>
  </div>,




<div className="card1" onMouseDown={handleMouseDown}>
<a 
  href="https://www.linkedin.com/pulse/northern-challengers-gearing-up-big-cricket-league-2024-pratik-jadhav-hrsvf/" 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ textDecoration: 'none', color: 'inherit' }}
>
  <img
    src="https://github.com/aayushwellness/RCAssets/blob/main/LatestUpdate/g4.png?raw=true"
    alt="Slide 4"
    onDragStart={handleDragStart}
    role="presentation"
  />
  <div className="date">05 DEC, 2024</div>
  <div className="title">Northern Challengers Gearing Up for Big Cricket League 2024</div>
  <div className="brief">The Northern Challengers, owned by visionary entrepreneur Devang Master, Founder and Chairman of Skybridge Incap Group, are poised to make a spectacular entry in the inaugural Big Cricket League (BCL). The much-anticipated tournament kicks off on December 12 at the iconic Lalbai Contractor Stadium in Surat, with the Challengers fielding a star-studded team that blends experience with promising young talent.</div>
</a>
</div>,



<div className="card1" onMouseDown={handleMouseDown}>
<a 
  href="https://www.aninews.in/news/sports/cricket/big-cricket-league-hosts-players-draft-set-to-begin-on-december-1220241203125738/" 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ textDecoration: 'none', color: 'inherit' }}
>
  <img
    src="https://github.com/aayushwellness/RCAssets/blob/main/LatestUpdate/g1new.jpeg?raw=true"
    alt="Slide 2"
    onDragStart={handleDragStart}
    role="presentation"
  />
  <div className="date">03 DEC, 2024</div>
  <div className="title">Big Cricket League hosts players draft, set to begin on December 12</div>
  <div className="brief">
    Mumbai (Maharashtra) [India], December 3 (ANI): The inaugural players draft of the Big Cricket League held in Mumbai featured six teams, who picked 36 former international stars in their teams.
  </div>
</a>
</div>,

<div className="card1" onMouseDown={handleMouseDown}>
    <a 
      href="https://www.insidesport.in/cricket/list-of-all-squad-of-big-cricket-league/" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <img
        src="https://github.com/aayushwellness/RCAssets/blob/main/LatestUpdate/G1.jpg?raw=true"
        alt="Slide 1"
        onDragStart={handleDragStart}
        role="presentation"
      />
      <div className="date">02 DEC, 2024</div>
      <div className="title">The Big Cricket League will feature star veteran players such as Shikhar Dhawan, Suresh Raina, and Irfan Pathan in December!</div>
      <div className="brief">The Big Cricket League (BCL) debuts this December in Surat, uniting amateur players with cricket legends like Shikhar Dhawan, Suresh Raina, and Irfan Pathan in a unique T20 tournament. Founded by RP Singh, BCL offers emerging talent a chance to play alongside seasoned pros, blending skill with experience.</div>
    </a>
  </div>,
  


];

const SectionFifth = () => {
  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <AliceCarousel
  mouseTracking
  items={items}
  autoPlay
  infinite
  autoPlayInterval={5000} // 5 seconds between slides
  animationDuration={2000} // 2 seconds animation duration
  disableDotsControls
  disableButtonsControls
  responsive={{
    0: {
      items: 1, // Show 1 card at a time on mobile
    },
    200: {
      items: 1, // Show 1 card at a time on small screens
    },
    600: {
      items: 2, // Show 2 cards at a time on medium screens
    },
    1024: {
      items: 3, // Show 3 cards at a time on larger screens
    },
  }}
/>
    </div>
  );
};

export default SectionFifth;
